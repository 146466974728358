<template>
    <date-picker ref="datepicker" type="month" :disabled-date="disabledFutureDate" :value="value" value-type="MMMM YYYY" :bootstrap-styling="false" :clearable="false" :disabled="loading || disabled" @input="handleInput">
        <template #input>
            <button class="rounded-md border border-gray-200 px-4 py-2 bg-white text-sm font-medium text-gray-700 z-0 flex items-center focus:outline-hidden focus:ring-2 hover:bg-gray-50 focus:ring-offset-2 focus:ring-blue-500 inline-flex w-full justify-between disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none" :disabled="loading || disabled" :class="{'cursor-not-allowed': loading, 'border-red-300': error, 'text-red-300': error }">
                <span v-if="value">{{ value }}</span>
                <span v-else>Select a Month and Year</span>
                <span class="-mr-1 ml-3 h-5 w-5 text-gray-700">
                    <svg class="text-gray-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" aria-hidden="true">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </span>
            </button>
        </template>
    </date-picker>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    name: 'MonthPicker',
    props: {
        name: { required: true, type:String },
        loading: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        disableFutureDates: { type: Boolean, default: false },
        value: { type: String, default: '' },
        error: { type: Boolean, default: false }
    },
    components: {
        DatePicker,
    },
    methods: {
        handleInput(value){
            this.$emit('input', value);
        },
    },
    computed: {
        disabledFutureDate(){
            return this.disableFutureDates ? (date) => date >= new Date() : () => '';
        }
    },
};
</script>

<style scoped>
.mx-icon-calendar {
    display: none;
}

.mx-datepicker {
    width: 100%;
}

.mx-datepicker svg{
    width: inherit;
    height: inherit;
    vertical-align: baseline;
    overflow: visible;
}

.mx-datepicker-range{
    width: 100%;
}
</style>
