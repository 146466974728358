import _ from 'lodash';

export function translate(key, substitutions = {}) {
    let translation = _.get(window.translations, key);

    if (translation === undefined) {
        throw new Error(`Missing translation [${key}]`);
    }

    for (const [key, value] of Object.entries(substitutions)) {
        translation = translation.replace(`:${key}`, value);
    }

    return translation;
};
