<script setup>
import BaseInput from '@/components/layouts/BaseInput.vue';
import BaseSelect from '@/components/layouts/BaseSelect.vue';
import InvalidFeedback from '@/components/layouts/InvalidFeedback.vue';
import { ref, computed } from 'vue';
import { smartyClient, buildLookup, formatSuggestion } from '../../clients/Smarty.js';
import states from '@/utils/states.json';
import MonthPicker from './MonthPicker.vue';
import { translate } from '@/helpers/localization.js';

const props = defineProps({
    street: { required: true, type: String },
    city: { required: true, type: String },
    state: { required: true, type: String },
    zip: { required: true, type: String },
    startDate: {type: String, default: ''},
    endDate: {type: String, default: ''},
    showStartDate: {type: Boolean, default: false},
    showEndDate: {type: Boolean, default: false},
    streetErrorMessage: {type:String, default:''},
    cityErrorMessage: {type:String, default:''},
    stateErrorMessage: {type:String, default:''},
    zipErrorMessage: {type:String, default:''},
    startDateErrorMessage: {type:String, default:''},
    endDateErrorMessage: {type:String, default:''},
});

const emit = defineEmits([
    'update:street',
    'update:city',
    'update:state',
    'update:zip',
    'update:startDate',
    'update:endDate',
    'update:streetErrorMessage',
    'update:cityErrorMessage',
    'update:stateErrorMessage',
    'update:zipErrorMessage',
    'update:startDateErrorMessage',
    'update:endDateErrorMessage',
]);

const street = computed({
    get() {
        return props.street;
    },
    set(value) {
        streetErrorMessage.value = null;
        emit('update:street', value);

        if (autocompletingAddress.value) {
            autocompletingAddress.value = false;

            return;
        }

        if (!value) {
            suggestions.value = [];

            return;
        }

        if (value.replace(' ', '').length >= 5) {
            queryAutocompleteForSuggestions(value);
        }
    }
});

const city = computed({
    get() {
        return props.city;
    },
    set(value) {
        cityErrorMessage.value = null;
        emit('update:city', value);
    }
});

const state = computed({
    get() {
        return props.state;
    },
    set(value) {
        stateErrorMessage.value = null;
        emit('update:state', value);
    }
});

const zip = computed({
    get() {
        return props.zip;
    },
    set(value) {
        zipErrorMessage.value = null;
        emit('update:zip', value);
    }
});

const startDate = computed({
    get() {
        return props.startDate;
    },
    set(value) {
        startDateErrorMessage.value = null;
        emit('update:startDate', value);
    }
});

const endDate = computed({
    get() {
        return props.endDate;
    },
    set(value) {
        endDateErrorMessage.value = null;
        emit('update:endDate', value);
    }
});

const streetErrorMessage = computed({
    get() {
        return props.streetErrorMessage;
    },
    set(value) {
        emit('update:streetErrorMessage', value);
    }
});

const cityErrorMessage = computed({
    get() {
        return props.cityErrorMessage;
    },
    set(value) {
        emit('update:cityErrorMessage', value);
    }
});

const stateErrorMessage = computed({
    get() {
        return props.stateErrorMessage;
    },
    set(value) {
        emit('update:stateErrorMessage', value);
    }
});

const zipErrorMessage = computed({
    get() {
        return props.zipErrorMessage;
    },
    set(value) {
        emit('update:zipErrorMessage', value);
    }
});

const startDateErrorMessage = computed({
    get() {
        return props.startDateErrorMessage;
    },
    set(value) {
        emit('update:startDateErrorMessage', value);
    }
});

const endDateErrorMessage = computed({
    get() {
        return props.endDateErrorMessage;
    },
    set(value) {
        emit('update:endDateErrorMessage', value);
    }
});

const suggestions = ref([]);
const autocompletingAddress = ref(false);

const formattedSuggestions = computed(() => {
    return suggestions.value.map(suggestion => {
        const formatted = formatSuggestion(suggestion);

        suggestion.address = formatted.address;
        suggestion.selected = formatted.selected;

        return suggestion;
    });
});

function queryAutocompleteForSuggestions(query) {
    const lookup = buildLookup(query);

    smartyClient.send(lookup).then(response => {
        suggestions.value = response.result;
    });
}

function autocompleteAddress(suggestion) {
    autocompletingAddress.value = true;
    const secondary = suggestion.secondary ? ` ${suggestion.secondary}` : '';

    street.value = suggestion.streetLine + secondary;
    city.value = suggestion.city;
    state.value = suggestion.state;
    zip.value = suggestion.zipcode;
    suggestions.value = [];
}

function selectSuggestion(suggestion) {
    if (suggestion.entries <= 1) {
        autocompleteAddress(suggestion);

        return;
    }

    this.queryAutocompleteForSuggestions(suggestion);
}
</script>

<template>
    <section class="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div class="relative group sm:col-span-6">
            <label class="sr-only">
                {{ translate('autocomplete_address_form.street_address') }}
            </label>

            <label for="street" class="block text-sm font-medium text-gray-700">
                {{ translate('autocomplete_address_form.street_address') }}
            </label>

            <input
                name="street"
                :placeholder="translate('autocomplete_address_form.begin_typing_address')"
                type="text"
                v-model="street"
                autocomplete="smarty"
                :class="{
                    'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': !!streetErrorMessage,
                }"
                class="block w-full px-3 py-2 mt-1 placeholder-gray-400 border border-gray-300 rounded-md shadow-xs appearance-none focus:outline-hidden focus:ring-blue-500 focus:border-blue-500"
            >

            <ul class="absolute z-50 hidden w-full overflow-y-scroll bg-white rounded-lg shadow-sm top-full max-h-48 group-focus-within:block">
                <li :tabindex="index" class="block p-2 hover:bg-gray-200 hover:cursor-pointer" @click="selectSuggestion(suggestion)" v-for="(suggestion, index) in formattedSuggestions" :key="index">
                    {{ suggestion.address }}
                </li>
            </ul>

            <invalid-feedback :error="!!streetErrorMessage">
                {{ streetErrorMessage }}
            </invalid-feedback>
        </div>

        <div class="sm:col-span-2">
            <label class="sr-only">
                {{ translate('autocomplete_address_form.city') }}
            </label>

            <label for="city" class="block text-sm font-medium text-gray-700">
                {{ translate('autocomplete_address_form.city') }}
            </label>

            <BaseInput
                name="city"
                type="text"
                v-model="city"
                autocomplete="smarty"
                class="mt-1"
                :error="!!cityErrorMessage"
            />

            <invalid-feedback :error="!!cityErrorMessage">
                {{ cityErrorMessage }}
            </invalid-feedback>
        </div>

        <div class="sm:col-span-2">
            <label class="sr-only">State</label>

            <label for="state" class="block text-sm font-medium text-gray-700">
                {{ translate('autocomplete_address_form.state') }}
            </label>

            <BaseSelect
                name="state"
                id="state"
                :error="!!stateErrorMessage"
                class="mt-1"
                type="text"
                v-model="state"
                autocomplete="smarty"
            >
                <option value="" disabled>
                    {{ translate('autocomplete_address_form.select_a_state') }}
                </option>

                <option v-for="state in states" :key="state.abbreviation" :value="state.abbreviation">
                    {{ state.name }}
                </option>
            </BaseSelect>

            <invalid-feedback :error="!!stateErrorMessage">
                {{ stateErrorMessage }}
            </invalid-feedback>
        </div>

        <div class="sm:col-span-2">
            <label class="sr-only">
                {{ translate('autocomplete_address_form.zip') }}
            </label>

            <label for="zip" class="block text-sm font-medium text-gray-700">
                {{ translate('autocomplete_address_form.zip') }}
            </label>

            <BaseInput
                name="zip"
                maxlength="5"
                type="text"
                v-model="zip"
                autocomplete="smarty"
                :error="!!zipErrorMessage"
                class="mt-1"
            />

            <invalid-feedback :error="!!zipErrorMessage">
                {{ zipErrorMessage }}
            </invalid-feedback>
        </div>

        <div v-if="showStartDate" class="sm:col-span-2">
            <label class="sr-only">Start Date</label>
            <label for="startDate" class="block text-sm font-medium text-gray-700">
                {{ translate('autocomplete_address_form.start_date') }}
            </label>

            <MonthPicker
                name="startDate"
                v-model="startDate"
                :error="!!startDateErrorMessage"
                :disable-future-dates="true"
            />

            <invalid-feedback :error="!!startDateErrorMessage">
                {{ startDateErrorMessage }}
            </invalid-feedback>
        </div>

        <div v-if="showEndDate" class="sm:col-span-2">
            <label class="sr-only">
                {{ translate('autocomplete_address_form.end_date') }}
            </label>

            <label for="beginDate" class="block text-sm font-medium text-gray-700">
                {{ translate('autocomplete_address_form.end_date') }}
            </label>

            <MonthPicker
                name="endDate"
                v-model="endDate"
                :error="!!endDateErrorMessage"
                :disable-future-dates="true"
            />

            <invalid-feedback :error="!!endDateErrorMessage">
                {{ endDateErrorMessage }}
            </invalid-feedback>
        </div>
    </section>
</template>
