import Vue from 'vue';
import moment from 'moment-timezone';

function getUserTimezone() {
    var [,timezone] = document.cookie.match(/timezone=([^;]+)/) ?? [null, 'UTC'];

    const decodedTimezone = decodeURIComponent(timezone);

    if (moment.tz.zone(decodedTimezone) === null) {
        return 'UTC';
    }

    return decodedTimezone;
}

Vue.filter('datetime', (date, format = 'MMMM DD, YYYY • h:mma') => moment(date).tz(getUserTimezone()).format(format));

Vue.filter('date', (date, format = 'MMMM DD, YYYY') => moment(date).tz(getUserTimezone()).format(format));

Vue.filter('sizeForHumans', function (size) {
    const suffixes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const factor = Math.floor((size.toString().length - 1) / 3);

    return `${(size / Math.pow(1024, factor)).toFixed(2)} ${suffixes[factor]}`;
});
