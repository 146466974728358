<template>
    <button type="button" @click="$emit('click', $event)" :disabled="processing" :class="classes" :data-tooltip="processing ? processingText : tooltip">
        <template v-if="processing">
            <svg class="animate-spin h-5 w-5" :class="{'shrink-0 -ml-1 mr-3 text-white': !circular}" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
        </template>
        <template v-if="!processing">
            <slot />
        </template>
        <span v-else-if="!circular">{{ processingText }}</span>
    </button>
</template>

<script>
export default {
    name: 'BaseButton',

    props: {
        xs: {default:false, type:Boolean},
        sm: {default:false, type:Boolean},
        md: {default:false, type:Boolean},
        lg: {default:false, type:Boolean},
        xl: {default:false, type:Boolean},
        primary: {default:false, type:Boolean},
        secondary: {default:false, type:Boolean},
        white: {default:false, type:Boolean},
        transparent: {default:false, type:Boolean},
        danger: {default:false, type:Boolean},
        dangerSoft: {default:false, type:Boolean},
        warning: {default:false, type:Boolean},
        success: {default:false, type:Boolean},
        outline: {default:false, type:Boolean},
        circular: {default:false, type:Boolean},
        badge: {default:false, type:Boolean},
        processing: {default:false, type:Boolean},
        processingText: {default:'Processing...', type:String},
        tooltip: {required:false, type:String},
    },

    computed: {
        classes(){
            if (this.circular) {
                return this.getCircularClasses;
            }

            return {
                'inline-flex items-center justify-center font-medium focus:outline-hidden focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none group': true,
                'tooltip tooltip-top': Boolean(this.tooltip),
                'px-2.5 py-1.5 text-xs rounded': this.xs,
                'px-3 py-2 text-sm leading-4 rounded-md': this.sm,
                'px-4 py-2 text-sm rounded-md': this.md,
                'px-3 py-0.5 rounded-md text-sm': this.badge,
                'px-4 py-2 text-base rounded-md': this.lg,
                'px-6 py-3 text-base rounded-md': this.xl,
                'border border-transparent text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 shadow-xs': this.primary,
                'border border-transparent text-blue-700 bg-blue-100 hover:bg-blue-200 focus:ring-blue-500 shadow-xs': this.secondary,
                'border border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-blue-500 shadow-xs': this.white,
                'bg-transparent text-gray-500 hover:text-gray-800 focus:ring-blue-500': this.transparent,
                'border border-transparent text-white bg-red-600 hover:bg-red-700 focus:ring-red-500': this.danger,
                'border border-transparent text-white bg-green-600 hover:bg-green-700 focus:ring-green-500': this.success,
                'border border-transparent bg-yellow-100 text-yellow-700 hover:bg-yellow-200 focus:ring-yellow-800': this.warning,
                'border border-transparent text-red-700 bg-red-100 hover:bg-red-200 focus:ring-red-500': this.dangerSoft,
                'cursor-not-allowed': this.processing,
            };
        },

        getCircularClasses(){
            const defaultClasses = {
                'inline-flex items-center border border-transparent rounded-full focus:outline-hidden focus:ring-2 focus:ring-offset-2 group': true,
                'tooltip tooltip-top': Boolean(this.tooltip),
                'p-1': this.xs,
                'p-1.5': this.sm,
                'p-2': this.md || this.lg,
                'p-3': this.xl,
                'cursor-not-allowed': this.processing,
            };

            if (this.transparent) {
                return {
                    ...defaultClasses,
                    'hover:bg-gray-50': true,
                    'text-gray-400 hover:text-gray-500 focus:ring-gray-500': this.white,
                    'text-red-400 hover:text-red-500 focus:ring-red-500': this.danger,
                    'text-green-400 hover:text-green-500 focus:ring-green-500': this.success,
                    'text-blue-400 hover:text-blue-500 focus:ring-blue-500': this.primary,
                    'text-yellow-400 hover:text-yellow-500 focus:ring-yellow-500': this.warning,
                };
            }

            return {
                ...defaultClasses,
                'shadow-xs': true,
                'text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': this.primary,
                'text-blue-700 bg-blue-100 hover:bg-blue-200 focus:ring-blue-500': this.secondary,
                'bg-gray-50 hover:bg-gray-100 text-gray-400 hover:text-gray-500 focus:ring-gray-500': this.white,
            };
        }
    },
};
</script>
