
import SmartySDK from 'smartystreets-javascript-sdk';

const SmartyCore = SmartySDK.core;
const credentials = new SmartyCore.SharedCredentials(import.meta.env.VITE_SMARTY_EMBEDDED_API_KEY);
const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses(['us-autocomplete-pro-cloud']);

export const smartyClient = clientBuilder.buildUsAutocompleteProClient();

export function formatSuggestion(suggestion) {
    const secondary = suggestion.secondary ? ` ${suggestion.secondary}` : '';
    const entries = suggestion.entries > 1 ? ` (${suggestion.entries} more entries)` : '';
    const address = `${suggestion.streetLine}${secondary}${entries} ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`;
    const selected = `${suggestion.streetLine}${secondary} (${suggestion.entries}) ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`;

    return {
        address,
        selected,
    };
}

export function buildLookup(streetAddress) {
    const lookup = new SmartySDK.usAutocompletePro.Lookup(streetAddress);

    if (streetAddress.entries > 1) {
        lookup.search = streetAddress.streetLine;
        lookup.selected = formatSuggestion(streetAddress).selected;
    }

    return lookup;
}
